<template>
  <cotizador-wrapper>
    <div class="content pb-5">
      <div class="row">
        <div class="col-12 flex justify-content-center">
          <p class="text-saludo">
            Un gusto saludarte
            <strong>{{ datosCotizacion.datosCliente.nombre }}</strong
            >, solicitaste una cotización de AppingErp con las siguientes
            características:
          </p>
        </div>
      </div>
      <div class="row flex justify-content-center">
        <div class="content-cotizacion col-10">
          <div class="row flex">
            <div class="col-4 p-4">
              <Card-element>
                <template #header>
                  <div class="box-icon-compania mt-3">
                    <i
                      class="pi pi-users flex justify-content-center pt-3 p-2"
                      style="font-size: 3.5rem; color: #a203dd"
                    ></i>
                  </div>
                </template>
                <template #content>
                  <p class="text-center">
                    <strong>Compañías:</strong>
                    {{ datosCotizacion.companias }}
                  </p>
                  <p class="text-center">
                    <strong>Usuarios:</strong>
                    {{ datosCotizacion.usuarios }}
                  </p>
                </template>
              </Card-element>
            </div>
            <div class="col-8 p-4">
              <cotizador-moduls :modulos="datosCotizacion.modulos" />
            </div>
          </div>
          <div class="row flex justify-content-center">
            <cotizador-electronic
              v-if="datosCotizacion?.documentosMes > 0"
              :documentos="datosCotizacion.documentos"
              :documentosMes="datosCotizacion.documentosMes"
            />
          </div>
          <div class="row">
            <div class="col-12 text_datos pl-8">
              <p class="my-2">
                <strong>Nombre:</strong>
                {{ datosCotizacion.datosCliente.nombre }}
              </p>
              <p class="my-2">
                <strong>Correo:</strong>
                {{ datosCotizacion.datosCliente.email }}
              </p>
              <p class="my-2">
                <strong>Teléfono:</strong>
                {{ datosCotizacion.datosCliente.telefono }}
              </p>
              <p class="my-2">
                <strong>Asesor:</strong>
                {{ `${user.Nombres} ${user.Apellidos}` }}
              </p>
              <p class="my-2">
                <strong>Comentarios:</strong>
                {{ datosCotizacion.datosCliente.comentarios }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 flex justify-content-center flex-wrap valores">
          <div class="mx-2">
            <p style="color: #a203dd" class="m-0">
              <strong>{{ `$${datosCotizacion.ValorMensual}` }}</strong
              >/Mes
            </p>
          </div>
          <div class="mx-2">
            <p class="m-0">
              <strong>{{ `$${datosCotizacion.ValorAnual}` }}</strong
              >/Año
            </p>
          </div>
          <div class="mx-2 w-full text-center">
            <p class="m-0">
              <span style="color: #a203dd">Firma electrónica: </span>
              <strong>{{ `$${datosCotizacion.ValorFirma}` }}</strong
              >/Año
            </p>
          </div>
        </div>
        <div
          class="col-10 px-5 flex justify-content-center text-center box_terminos"
        >
          <p>
            <strong style="color: #a203dd">Términos y Condiciones:</strong>
            Impuestos incluidos - Cotización válida por un mes - La firma
            electrónica se paga anticipada - Ahorra un mes por pago anual - El
            valor se ajustará al IPC al inicio de cada año.
          </p>
        </div>
      </div>
    </div>
  </cotizador-wrapper>
  <div class="col-12 flex justify-content-end">
    <button-element
      label="Volver"
      class="btn-volver w-50 mr-1"
      @click="$emit('volver')"
    />
    <button-element
      label="Capturar"
      class="btn-volver w-50 mr-1 p-button-success"
      @click="capturar"
    />
    <button-element
      label="Nueva Cotización"
      class="btn-nuevacotizacion"
      @click="$emit('volver', true)"
    />
  </div>
</template>
<script>
import CotizadorWrapper from "@/components/maestros/cotizador/CotizadorWrapper.vue";
import CotizadorElectronic from "@/components/maestros/cotizador/CotizadorElectronic.vue";
import CotizadorModuls from "@/components/maestros/cotizador/CotizadorModuls.vue";
import html2canvas from "html2canvas";
import moment from "moment";
import { mapState } from "vuex";
export default {
  props: {
    datosCotizacion: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["volver"],
  components: {
    CotizadorWrapper,
    CotizadorElectronic,
    CotizadorModuls,
  },
  methods: {
    async capturar() {
      const $toast = this.$toast;
      const cliente = this.datosCotizacion?.datosCliente ?? {};
      const $store = this.$store;
      $store.commit("setIsLoading", true);
      const canvas = await html2canvas(document.getElementById("wrapper"), {
        useCORS: true,
        allowTaint: false,
        scale: 2,
        imageTimeout: 0,
      }).catch(console.log);
      document.body.appendChild(canvas);

      canvas.toBlob(function (blob) {
        const item = new window.ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]);

        // Descargar imagen
        const link = document.createElement("a");
        link.download = `cotizacion-${cliente?.nombre?.replaceAll(
          " ",
          ""
        )}-${moment().format("YYYY-MM-DDD")}.png`;
        link.href = canvas.toDataURL();
        link.click();
        $store.commit("setIsLoading", false);

        // Eliminar canvas
        document.body.removeChild(canvas);

        $toast.add({
          severity: "success",
          summary: "Captura finalizada",
          detail: "Se copio al portapapeles",
          life: 3000,
        });
      });
    },
  },
  computed: {
    ...mapState(["auth"]),
    user() {
      return this.auth?.user ?? {};
    },
  },
};
</script>
